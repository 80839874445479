import React, { useCallback, useMemo } from 'react';
import { Switch } from 'react-router';

import Toolbar from '@material-ui/core/Toolbar';

import LayoutContextProvider from 'pages/Layout/context';
import Drawer from 'pages/Layout/Drawer';
import { Footer } from 'pages/Layout/Footer';
import { Header } from 'pages/Layout/Header';
import Home from 'pages/Private/Home';
import { UserProfile } from 'pages/Private/Profile';
import { AcessCodeCheck } from 'pages/Public/AcessCode';
import { AccessControlRoutes } from 'routes/roles/AccessControl';
import { AdministrationRoutes } from 'routes/roles/AdministrationRoutes';
import { CashierRoutes } from 'routes/roles/CashierRoutes';
import { CollaboratorsRoutes } from 'routes/roles/CollaboratorsRoutes';
import { ConferenceRoutes } from 'routes/roles/ConferenceRoutes';
import { ExpeditionRoutes } from 'routes/roles/ExpeditionRoutes';
import { GeneralRegistrationRoutes } from 'routes/roles/GeneralRegistrationRoutes';
import { GeneralReleasesRoutes } from 'routes/roles/GeneralReleasesRoutes';
import { ImplementationRoutes } from 'routes/roles/ImplementationRoutes';
import { PcpRoutes } from 'routes/roles/PcpRoutes';
import { ProductionRoutes } from 'routes/roles/ProductionRoutes';
import { ReceivementRoutes } from 'routes/roles/ReceivementRoutes';
import { ReportRoutes } from 'routes/roles/ReportRoutes';
import { SacRoutes } from 'routes/roles/SacRoutes';
import { ServiceOrderRoutes } from 'routes/roles/ServiceOrderRoutes';
import { StockRoutes } from 'routes/roles/StockRoutes';
import { ToQuoteRoutes } from 'routes/roles/ToQuote';
import { Warehouse } from 'routes/roles/Warehouse';
import { WarrantyRoutes } from 'routes/roles/WarrantyRoutes';
import { Route } from 'routes/Route';

import { useStyles } from './styles';

const Private = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen((prevState) => !prevState);
  }, []);

  const contextValue = useMemo(
    () => ({
      anchorEl,
      setAnchorEl,
      mobileMoreAnchorEl,
      setMobileMoreAnchorEl,
      menuId,
      mobileMenuId,
      drawerOpen,
      handleDrawerToggle,
    }),
    [
      anchorEl,
      setAnchorEl,
      mobileMoreAnchorEl,
      setMobileMoreAnchorEl,
      menuId,
      mobileMenuId,
      drawerOpen,
      handleDrawerToggle,
    ],
  );

  return (
    <LayoutContextProvider value={contextValue}>
      <div className={classes.root}>
        <Header />
        <Drawer />
        <main className={classes.main}>
          <div className={classes.content}>
            <Toolbar />
            <Switch>
              <Route isPrivate path='/code_acess' component={AcessCodeCheck} />
              <Route isPrivate path='/home' component={Home} />
              <Route isPrivate path='/pcp' component={PcpRoutes} />
              <Route isPrivate path='/profile' component={UserProfile} />
              <Route isPrivate path='/expedition' component={ExpeditionRoutes} />
              <Route isPrivate path='/sac' component={SacRoutes} />
              <Route isPrivate path='/service-order' component={ServiceOrderRoutes} />
              <Route isPrivate path='/production' component={ProductionRoutes} />
              <Route isPrivate path='/stock' component={StockRoutes} />
              <Route isPrivate path='/warranty' component={WarrantyRoutes} />
              <Route isPrivate path='/receivement' component={ReceivementRoutes} />
              <Route isPrivate path='/reports-general' component={ReportRoutes} />

              <Route isPrivate path='/conference' component={ConferenceRoutes} />
              <Route isPrivate path='/general-releases' component={GeneralReleasesRoutes} />
              <Route isPrivate path='/general-registration' component={GeneralRegistrationRoutes} />
              <Route isPrivate path='/cashier' component={CashierRoutes} />
              <Route isPrivate path='/implementation' component={ImplementationRoutes} />
              <Route isPrivate path='/access-control' component={AccessControlRoutes} />
              <Route isPrivate path='/quote' component={ToQuoteRoutes} />
              <Route isPrivate path='/collaborators' component={CollaboratorsRoutes} />
              <Route isPrivate path='/administration' component={AdministrationRoutes} />
              <Route isPrivate path='/warehouse' component={Warehouse} />
            </Switch>
          </div>
          <Footer />
        </main>
      </div>
    </LayoutContextProvider>
  );
};

export default Private;
