/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, createContext } from 'react';

import Grid from '@material-ui/core/Grid';

import { Select } from 'components';
import { Field, Form, Formik } from 'formik';

import { ManualDialogContext } from '../../..';
import { Itens } from '../../Itens';

export const ItensFormContext = createContext();

const createContainerOptions = (client, containers) => {
  const containerOptions = [];
  for (let i = 1; i <= containers; i++) {
    containerOptions.push({ value: `${client} LOTE ${i}`, label: `${client} LOTE ${i}` });
  }
  return containerOptions;
};

export function ItensForm() {
  const { client, containers } = useContext(ManualDialogContext);

  // Estado para armazenar os itens de cada lote
  const [itemsByContainer, setItemsByContainer] = useState({});
  const [selectedContainer, setSelectedContainer] = useState('');

  // Atualiza o lote selecionado e carrega os itens existentes para esse lote
  const handleContainerChange = (value) => {
    setSelectedContainer(value);
  };

  const handleSubmit = () => {
    // Ao submeter, salvar os itens do lote atual
    setItemsByContainer((prevItems) => ({
      ...prevItems,
      [selectedContainer]: itemsByContainer[selectedContainer] || [],
    }));
    console.log('Itens salvos:', itemsByContainer[selectedContainer]);
  };

  useEffect(() => {
    // Quando o lote for alterado, carregar os itens do lote selecionado
    if (selectedContainer) {
      const loadedItems = itemsByContainer[selectedContainer];
      setItemsByContainer((prev) => ({ ...prev, [selectedContainer]: loadedItems }));
    }
  }, [selectedContainer]);

  return (
    <>
      <Formik initialValues={{ container: '' }} onSubmit={handleSubmit}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                name='container'
                component={Select}
                label='Lote'
                options={createContainerOptions(client, containers)}
                value={selectedContainer}
                onChange={(e) => {
                  handleContainerChange(e);
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Formik>

      {selectedContainer && (
        <ItensFormContext.Provider
          value={{ itemsByContainer, setItemsByContainer, selectedContainer }}
        >
          <Itens />
        </ItensFormContext.Provider>
      )}
    </>
  );
}
