/* eslint-disable no-restricted-imports */
import React, { createContext, useState } from 'react';

import { Box, Step, StepLabel, Stepper } from '@material-ui/core';

import { Dialog } from 'components';

import { Navigation } from './components/Navigation';
import { ClientForm } from './components/Steps/ClientForm';
import { ItensForm } from './components/Steps/ItensForm';

export const ManualDialogContext = createContext();

export function ManualDialog({ isOpen, handleClose }) {
  if (!isOpen) {
    return <></>;
  }

  const steps = ['Cliente/Ganhador', 'Itens', 'Resumo'];

  const [activeStep, setActiveStep] = useState(0);

  const [client, setClient] = useState('');
  const [containers, setContainers] = useState(1);

  const getStepContent = (activeStep) => {
    const steps = {
      0: <ClientForm />,
      1: <ItensForm />,
    };

    return steps[activeStep];
  };

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='xl'>
      <Dialog.Title onClose={handleClose}>Pedido manual</Dialog.Title>
      <ManualDialogContext.Provider
        value={{ activeStep, handleNext, handleBack, setClient, client, containers, setContainers }}
      >
        <Dialog.Content>
          <Box>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((item, index) => (
                <Step key={index}>
                  <StepLabel>{item}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {getStepContent(activeStep)}
          </Box>
        </Dialog.Content>

        <Dialog.Actions>
          <Navigation />
        </Dialog.Actions>
      </ManualDialogContext.Provider>
    </Dialog>
  );
}
