import React, { Fragment, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Grid from '@mui/material/Grid';
import { Button, Checkbox, FormatDate, Spacer } from 'components';
import { SimpleTable } from 'components/SimpleTable';
import { useBoolean } from 'hooks/useBoolean';
import { warehouseService } from 'services';

import { WarehouseExpeditionContext } from './ExpeditionGenerate';

export function ExpeditionTables() {
  const { requests, loadingRequests, handleChangePage, page, refetchRequests } = useContext(
    WarehouseExpeditionContext,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [getItem, setItem] = useState([]);
  const [getSelectedRow, setSelectedRow] = useState([]);

  const [getSelectedAll, setSelectedAll] = useBoolean(false);

  const updateAllRowsChecked = () => {
    if (requests?.totalCount !== getSelectedRow.length) {
      setSelectedRow(requests?.request || []);
    } else {
      setSelectedRow([]);
    }
    setSelectedAll.toggle();
  };

  const columns = [
    {
      label: (
        <Checkbox
          color='secondary'
          checked={getSelectedAll}
          onChange={() => updateAllRowsChecked()}
        />
      ),
      align: 'center',
    },
    { label: 'Requerente', align: 'center' },
    { label: 'Modelo', align: 'center' },
    { label: 'Cliente', align: 'center' },
    { label: 'CGC', align: 'center' },
    { label: 'Status', align: 'center' },
    { label: 'Criado em', align: 'center' },
    { label: 'Atualizado em', align: 'center' },
  ];

  function updateRowsChecked(item) {
    const updatedRow = [...getSelectedRow];

    const index = updatedRow.indexOf(item);
    if (index !== -1) {
      updatedRow.splice(index, 1);
      setSelectedAll.toFalse();
    } else {
      updatedRow.push(item);
    }

    if (updatedRow.length === requests?.totalCount) {
      setSelectedAll.toTrue();
    }

    setSelectedRow(updatedRow);
  }

  function resetStates() {
    setItem();
    setSelectedRow([]);
    setSelectedAll.toFalse();
  }

  const handleDownloadTagsClick = async (header_id) => {
    const { data } = await warehouseService.downloadMinuteTag(header_id);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, 'minuta.pdf');
  };

  async function handleGenerateMinute() {
    setIsLoading(true);
    toast.warning('Gerando minuta...');
    try {
      console.log('getSelectedRow =>', getSelectedRow);
      const { data } = await warehouseService.createMinute(getSelectedRow);
      console.log('data =>', data);
      await handleDownloadTagsClick(data.header_id);
      resetStates();
      refetchRequests();
      toast.success('Minuta gerada com sucesso.');
      location.reload();
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro na geração de minuta');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <SimpleTable
        headers={columns}
        totalCount={requests?.totalCount ?? 1}
        isLoading={loadingRequests}
        handleChangePage={handleChangePage}
        page={page}
      >
        {requests?.request?.map((row, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell align='center'>
                <Checkbox
                  checked={getSelectedAll || getSelectedRow?.some((item) => item === row)}
                  onChange={() => updateRowsChecked(row)}
                />
              </TableCell>
              <TableCell align='center'>{row?.user?.name}</TableCell>
              <TableCell align='center' style={{ fontWeight: 'bold' }}>
                {row?.model}
              </TableCell>
              <TableCell align='center'>{row?.client?.name}</TableCell>
              <TableCell align='center'>{row?.client?.cgc}</TableCell>
              <TableCell
                align='center'
                style={{
                  fontWeight: 'bold',
                  color:
                    row?.status_id === 1
                      ? 'yellow'
                      : row?.status_id === 2
                      ? 'green'
                      : row.status_id === 3
                      ? 'red'
                      : 'blue',
                }}
              >
                {row?.status?.description}
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy HH:mm'>{row?.created_at}</FormatDate>
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy HH:mm'>{row?.updated_at}</FormatDate>
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </SimpleTable>
      <Spacer size={30} />
      <Grid>
        <Button
          fullWidth
          color='secondary'
          disabled={getSelectedRow.length <= 0}
          onClick={() => handleGenerateMinute()}
          loading={isLoading}
        >
          Gerar minuta
        </Button>
      </Grid>
    </>
  );
}
