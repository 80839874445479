import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import IconButton from '@mui/material/IconButton';
import { FormatDate, Spacer, Text } from 'components';
import { SimpleTable } from 'components/SimpleTable';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { warehouseService } from 'services';
const columns = [
  { label: 'ID minuta', align: 'center' },
  { label: 'Requerente', align: 'center' },
  { label: 'Modelo', align: 'center' },
  { label: 'Objeto', align: 'center' },
  { label: 'Cliente', align: 'center' },
  { label: 'CGC', align: 'center' },
  { label: 'Status', align: 'center' },
  { label: 'Criado em', align: 'center' },
  { label: 'Atualizado em', align: 'center' },
  { label: 'Ações', align: 'center' },
];

export function ExpeditionFinalized() {
  const [search, setSearch] = useState('');
  const [status] = useState(4);

  const [page] = useState(1);
  const [rowsPerPage] = useState(100);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleSearchChange(event) {
    const value = event.target.value;
    setSearch(value.trim());
  }

  const [requests, , loadingRequests, refetchRequests] = useQuery(
    () => warehouseService.listRequests(page, rowsPerPage, search, status, false),
    [search, page, status],
  );

  const handleDownloadTagsClick = async (header_id) => {
    const { data } = await warehouseService.downloadMinuteTag(header_id);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, 'minuta.pdf');
  };

  const handleDownloadPostCardClick = async (request_id) => {
    const { data } = await warehouseService.createPostCard(request_id);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, 'post_card.pdf');
  };

  async function handleGetMinute(header_id) {
    try {
      await handleDownloadTagsClick(header_id);
      toast.success('Minuta gerada com sucesso.');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro na geração de minuta');
    }
  }

  async function handleGetMinutePostCard(request_id) {
    try {
      await handleDownloadPostCardClick(request_id);
      toast.success('Cartão de postagem gerado com sucesso.');
    } catch (err) {
      console.log('err', err);
      toast.error(err?.response?.data?.message || 'Erro na geração do cartão de postagem');
    }
  }

  return (
    <>
      <Spacer size={32} />
      <PageTitle>Finalizados</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            fullWidth
            endAdornment={<SearchIcon />}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>
      <Spacer size={32} />
      <SimpleTable
        headers={columns}
        totalCount={requests?.totalCount ?? 1}
        isLoading={loadingRequests}
        handleChangePage={handleChangePage}
        page={page}
      >
        {requests?.request?.map((row, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell align='center'>{row?.minute_item?.header_id}</TableCell>
              <TableCell align='center'>{row?.user?.name}</TableCell>
              <TableCell align='center' style={{ fontWeight: 'bold' }}>
                {row?.model}
              </TableCell>
              <TableCell align='center'>{row?.minute_item?.object?.full_object}</TableCell>
              <TableCell align='center'>{row?.service?.client?.name}</TableCell>
              <TableCell align='center'>{row?.service?.client?.cgc}</TableCell>
              <TableCell
                align='center'
                style={{
                  fontWeight: 'bold',
                  color:
                    row?.status_id === 1
                      ? 'yellow'
                      : row?.status_id === 2
                      ? 'green'
                      : row.status_id === 3
                      ? 'red'
                      : 'blue',
                }}
              >
                {row?.status?.description}
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy HH:mm'>{row?.created_at}</FormatDate>
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy HH:mm'>{row?.updated_at}</FormatDate>
              </TableCell>
              <TableCell align='center'>
                <IconButton
                  onClick={() => handleGetMinute(row?.minute_item?.header_id)}
                  color='primary'
                >
                  <DownloadForOfflineIcon fontSize='large' />
                </IconButton>
                <IconButton onClick={() => handleGetMinutePostCard(row?.id)} color='secondary'>
                  <LocalOfferIcon fontSize='large' />
                </IconButton>
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </SimpleTable>
    </>
  );
}
