import React, { useState } from 'react';

import CategoryIcon from '@mui/icons-material/Category';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import UpdateIcon from '@mui/icons-material/Update';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { MoreInfoDialog } from './MoreInfo/MoreInfoDialog';
import { UpdatedProduct } from './UpdatedProduct';
import { UpdatedStatus } from './UpdatedStatus';
const ITEM_HEIGHT = 48;

export function ActionMenu({ data, report, refetch }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMoreInfoDialog, setOpenMoreInfoDialog] = useState(false);
  const [openUpdatedStatus, setOpenUpdatedStatus] = useState(false);
  const [openUpdatedProduct, setOpenUpdatedProduct] = useState(false);

  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpenMoreInfoDialog() {
    setOpenMoreInfoDialog(true);
  }

  function handleCloseMoreInfoDialog() {
    setOpenMoreInfoDialog(false);
  }

  function handleUpdatedStatusDialog() {
    setOpenUpdatedStatus(true);
  }

  function handleCloseUpdatedStatusDialog() {
    setOpenUpdatedStatus(false);
  }

  function handleOpenUpdatedProductDialog() {
    setOpenUpdatedProduct(true);
  }

  function handleCloseUpdatedProductDialog() {
    setOpenUpdatedProduct(false);
  }

  return (
    <>
      <IconButton
        aria-label='more'
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='action-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '28ch',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenMoreInfoDialog();
          }}
        >
          <ListItemIcon>
            <InfoIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Detalhes' />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleUpdatedStatusDialog();
          }}
        >
          <ListItemIcon>
            <UpdateIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Atualizar Status' />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenUpdatedProductDialog();
          }}
        >
          <ListItemIcon>
            <CategoryIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Atualizar Produto' />
        </MenuItem>
        <MenuItem onClick={() => window.open(report, '_blank')} disabled={!report}>
          <ListItemIcon>
            <ReportProblemIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Ver laudo' />
        </MenuItem>
      </Menu>

      <MoreInfoDialog
        isOpen={openMoreInfoDialog}
        handleClose={handleCloseMoreInfoDialog}
        sinister={data?.sinister}
      />

      <UpdatedStatus
        isOpen={openUpdatedStatus}
        handleClose={handleCloseUpdatedStatusDialog}
        data={data}
        refetch={refetch}
      />

      <UpdatedProduct
        isOpen={openUpdatedProduct}
        handleClose={handleCloseUpdatedProductDialog}
        currentProduct={data?.sku}
        sinister={data?.sinister}
        refetch={refetch}
      />
    </>
  );
}
