import { gql } from '@apollo/client';

export const STOCK_REPORT = gql`
  mutation {
    stockReport {
      Voucher
      IMEI
      SKU
      Modelo
      Local
      Cliente
      Loja
      Lote
      Status_atual
      Condicao
      Triagem_funcional
      Grade
      Criado_em
      Atualizado_em
      Tela
      Laterais
      Traseira
      Defeitos_Adicionais
      Resultado_Triagem_Funcional
      Data_Recebimento
      Data_Funcional
      Data_Cosmetico
      Data_Laudo
      Data_Alocacao
      Data_Oracle
      Respostas_Funcional
      __typename @skip(if: true)
    }
  }
`;

export const ORACLE_REPORT = gql`
  mutation {
    oracleReport {
      Voucher
      IMEI
      SKU
      Grade
      Numero_do_Grade
      RI
      NF
      __typename @skip(if: true)
    }
  }
`;
