/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

import { useMutation, useQuery } from '@apollo/client';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { LIST_GRADES } from 'services/graphql/query/grade';
import {
  FIND_AVAIABLE_PRODUCT_BY_SINISTER_OR_IMEI,
  FIND_FIFO_PRODUCT,
} from 'services/graphql/query/service-orders';

import { ItensFormContext } from '../Steps/ItensForm';

export function Itens() {
  const { itemsByContainer, setItemsByContainer, selectedContainer } = useContext(ItensFormContext);
  console.log('itemsByContainer ==>', itemsByContainer);
  const [isSkuChecked, setIsSkuChecked] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [voucherImei, setVoucherImei] = useState('');
  const [sku, setSku] = useState('');

  const grades = useQuery(LIST_GRADES);

  const gradeOptions = (grades?.data?.listGrades || []).map((item) => ({
    value: item?.id,
    label: item?.description,
  }));

  const [findAvailableProduct] = useMutation(FIND_AVAIABLE_PRODUCT_BY_SINISTER_OR_IMEI, {
    onCompleted: (data) => {
      const { sinister, productSku } = data.findAvaiableProductBySinisterOrImei;
      setVoucherImei(sinister);
      setSku(productSku);
      addNewItem(sinister, productSku);
    },
    onError: (error) => {
      toast.error(`Erro: ${error.message || 'Erro ao encontrar o produto'}`);
    },
  });

  const [findFifoProduct] = useMutation(FIND_FIFO_PRODUCT, {
    onCompleted: (data) => {
      const { sinister, productSku } = data.findFifoProduct;
      setVoucherImei(sinister);
      setSku(productSku);
      addNewItem(sinister, productSku);
    },
    onError: (error) => {
      toast.error(`Erro: ${error.message || 'Erro ao encontrar o produto'}`);
    },
  });

  const currentContainer = itemsByContainer[selectedContainer] || {
    container: selectedContainer,
    itens: [],
  };

  const handleCheckboxChange = (e) => {
    setIsSkuChecked(e.target.checked);
    setSelectedGrade('');
    setVoucherImei('');
    setSku('');
  };

  const addNewItem = (sinister, productSku) => {
    const itemExists = currentContainer.itens.some(
      (item) => item.voucher_imei === sinister || item.sku === productSku,
    );

    if (itemExists) {
      toast.error('Erro: Produto já adicionado ao lote');
      return;
    }

    const updatedItens = [...currentContainer.itens, { voucher_imei: sinister, sku: productSku }];
    setItemsByContainer((prevItems) => ({
      ...prevItems,
      [selectedContainer]: { ...currentContainer, itens: updatedItens },
    }));

    setVoucherImei('');
    setSku('');
  };

  const removeItem = async (index) => {
    const updatedItens = currentContainer.itens.filter((_, i) => i !== index);
    setItemsByContainer((prevItems) => ({
      ...prevItems,
      [selectedContainer]: { ...currentContainer, itens: updatedItens },
    }));
  };

  async function handleReserveItem() {
    try {
      if (isSkuChecked) {
        await findFifoProduct({
          variables: { search: sku, gradeId: selectedGrade },
        });
      } else {
        await findAvailableProduct({
          variables: { search: voucherImei },
        });
      }
    } catch (error) {
      console.log('error ==>', error);
    }
  }

  const isButtonDisabled = () => {
    if (!voucherImei && !isSkuChecked) return true;
    if (isSkuChecked && (!selectedGrade || !sku)) return true;
    return false;
  };

  return (
    <>
      <Spacer size={32} />
      <Typography variant='h6'>Adicionar Item</Typography>
      <Grid container spacing={2}>
        {isSkuChecked ? (
          <>
            <Grid item xs={3}>
              <Formik initialValues={{ gradeId: '' }}>
                <Form>
                  <Field
                    label='Grade'
                    name='gradeId'
                    size='small'
                    options={gradeOptions}
                    onChange={setSelectedGrade}
                    component={Select}
                    loading={grades?.loading}
                  />
                </Form>
              </Formik>
            </Grid>
            <Grid item xs={3}>
              <Text
                name='sku'
                label='SKU'
                size='small'
                variant='outlined'
                fullWidth
                value={sku}
                disabled={!selectedGrade}
                onChange={(e) => setSku(e.target.value)}
                // onBlur={(e) => {
                //   if (selectedGrade) {
                //     findFifoProduct({
                //       variables: { search: e.target.value, gradeId: selectedGrade },
                //     });
                //   }
                // }}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={3}>
            <Text
              name='voucher_imei'
              label='IMEI/Voucher'
              size='small'
              variant='outlined'
              fullWidth
              value={voucherImei}
              onChange={(e) => setVoucherImei(e.target.value)}
            />
          </Grid>
        )}
        <Grid item xs={1}>
          <IconButton
            aria-label='adicionar item'
            color='primary'
            // onClick={addNewItem}
            onClick={async () => handleReserveItem()}
            disabled={isButtonDisabled()}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={<Checkbox checked={isSkuChecked} onChange={handleCheckboxChange} />}
            label='Consultar por SKU'
          />
        </Grid>
      </Grid>

      {currentContainer.itens.length > 0 && (
        <>
          <Spacer size={32} />
          <Typography variant='h6'>Itens Adicionados</Typography>
          {currentContainer.itens.map((item, index) => (
            <Grid container spacing={2} key={index} alignItems='center'>
              <Grid item xs={3}>
                <Text
                  name='voucher_imei'
                  label='IMEI/Voucher'
                  size='small'
                  variant='outlined'
                  fullWidth
                  value={item.voucher_imei}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <Text
                  name='sku'
                  label='SKU'
                  size='small'
                  variant='outlined'
                  fullWidth
                  value={item.sku}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label='remover item'
                  color='secondary'
                  onClick={() => {
                    removeItem(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </>
  );
}
