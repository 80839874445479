import React, { useContext, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { TablePagination } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Checkbox, Spacer, Table } from 'components';

import { WaitingOracleContext } from '..';

import { WaitingOracleBarcodeDialog } from './WaitingOracleBarcodeDialog';
import { WaitingOracleChangesDialog } from './WaitingOracleChangesDialog';

function getGradeColor(gradeId) {
  switch (gradeId) {
    case 1:
      return {
        label: 'LIKE NEW',
        color: '#FFFFFF', // Branco para LIKE NEW
        textColor: '#000000',
        border: '1px solid #000000',
      };
    case 2:
      return { label: 'EXCELENTE', color: '#0000FF', textColor: '#FFFFFF' }; // Azul para EXCELENTE
    case 3:
      return { label: 'MUITO BOM', color: '#008000', textColor: '#FFFFFF' }; // Verde para MUITO BOM
    case 4:
      return { label: 'BOM', color: '#FFFF00', textColor: '#000000' }; // Amarelo para BOM+
    case 5:
      return { label: 'REGULAR', color: '#FF0000', textColor: '#FFFFFF' }; // Vermelho para BOM
    case 6:
      return { label: 'QUEBRADO', color: '#000000', textColor: '#FFFFFF' }; // Preto para QUEBRADO
    default:
      return { label: 'SEM GRADE', color: '#CCCCCC', textColor: '#000000' }; // Cor padrão (cinza)
  }
}

function getAlterGradeColor(gradeId) {
  switch (gradeId) {
    case 1:
      return {
        label: '1+',
        color: '#FFFFFF', // Branco para LIKE NEW
        textColor: '#000000',
        border: '1px solid #000000',
      };
    case 2:
      return { label: '1', color: '#0000FF', textColor: '#FFFFFF' }; // Azul para EXCELENTE
    case 3:
      return { label: '2', color: '#008000', textColor: '#FFFFFF' }; // Verde para MUITO BOM
    case 4:
      return { label: '3', color: '#FFFF00', textColor: '#000000' }; // Amarelo para BOM+
    case 5:
      return { label: '4', color: '#FF0000', textColor: '#FFFFFF' }; // Vermelho para BOM
    case 6:
      return { label: '5', color: '#000000', textColor: '#FFFFFF' }; // Preto para QUEBRADO
    default:
      return { label: 'SEM GRADE', color: '#CCCCCC', textColor: '#000000' }; // Cor padrão (cinza)
  }
}

export function WaitingOracleTable() {
  const {
    data,
    loading,
    totalCount,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    getSelectedRow,
    setSelectedRow,
    getSelectedAll,
    setSelectedAll,
  } = useContext(WaitingOracleContext);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenChangesDialog, setIsOpenChangesDialog] = useState(false);
  const [itemSelect, setItemSelect] = useState();

  const updateAllRowsChecked = () => {
    if (!getSelectedAll) {
      const updatedRow = data?.findAllServiceForWaitingOracle?.serviceOrders.map((item) => ({
        sinister: item.sinister,
      }));
      setSelectedRow(updatedRow);
    } else {
      setSelectedRow([]);
    }
    setSelectedAll.toggle();
  };

  const columns = [
    {
      label: (
        <Checkbox
          color='secondary'
          checked={
            getSelectedAll ||
            getSelectedRow.length === data?.findAllServiceForWaitingOracle?.serviceOrders?.length
          }
          onChange={() => updateAllRowsChecked()}
        />
      ),
      align: 'center',
    },
    { label: 'Voucher', align: 'center' },
    { label: 'IMEI', align: 'center' },
    { label: 'SKU', align: 'center' },
    { label: 'Produto', align: 'center' },
    { label: 'Triagem funcional', align: 'center' },
    { label: 'Grade', align: 'center' },
    { label: 'Grade', align: 'center' },
    { label: 'Número RI', align: 'center' },
    { label: 'Nota fiscal', align: 'center' },
    { label: 'Alterações', align: 'center' },
    { label: 'Ações', align: 'center' },
  ];

  const updateRowsChecked = (item) => {
    const updatedRow = [...getSelectedRow];
    const index = updatedRow.findIndex((row) => row.sinister === item.sinister);

    if (index !== -1) {
      updatedRow.splice(index, 1);
      setSelectedAll.toFalse();
    } else {
      updatedRow.push({ sinister: item.sinister });
    }

    if (updatedRow.length === data?.findAllServiceForWaitingOracle?.serviceOrders?.length) {
      setSelectedAll.toTrue();
    }

    setSelectedRow(updatedRow);
  };

  function handleOpenDialog(item) {
    setItemSelect(item);
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  function handleOpenChangesDialog(item) {
    setItemSelect(item);
    setIsOpenChangesDialog(true);
  }

  function handleCloseChangesDialog() {
    setIsOpenChangesDialog(false);
  }

  return (
    <>
      <Spacer size={54} />

      <Table
        headers={columns}
        striped
        emptyMessage='Nenhum produto encontrado.'
        loading={loading}
        disableNumeration
      >
        {data?.findAllServiceForWaitingOracle?.serviceOrders?.map((item, index) => {
          const grade = getGradeColor(item?.grade?.id);
          const alterGrade = getAlterGradeColor(item?.grade?.id);
          return (
            <TableRow key={index}>
              <TableCell align='center'>
                <Checkbox
                  checked={
                    getSelectedAll ||
                    getSelectedRow?.some((object) => object?.sinister === item?.sinister)
                  }
                  onChange={() => updateRowsChecked({ sinister: item?.sinister })}
                />
              </TableCell>
              <TableCell align='center'>{item?.sinister}</TableCell>
              <TableCell align='center'>{item?.imei}</TableCell>
              <TableCell align='center'>{item?.sku?.sku}</TableCell>
              <TableCell align='center'>
                {item?.sku?.brand?.description +
                  ' ' +
                  item?.sku?.model?.description +
                  ' ' +
                  item?.sku?.storage?.description +
                  ' ' +
                  item?.sku?.color?.description}
              </TableCell>
              <TableCell align='center'>{item?.functionalScreeningResult}</TableCell>
              <TableCell align='center'>
                <Chip
                  label={grade.label}
                  style={{
                    backgroundColor: grade.color,
                    color: grade.textColor,
                    border: grade.border || 'none',
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <Chip
                  label={alterGrade.label}
                  style={{
                    backgroundColor: grade.color,
                    color: grade.textColor,
                    border: grade.border || 'none',
                  }}
                />
              </TableCell>
              <TableCell align='center'>{item?.ri}</TableCell>
              <TableCell align='center'>{item?.invoice}</TableCell>
              <TableCell align='center'>
                {console.log(
                  'item.serviceOrdersLogsData.length',
                  item.serviceOrdersLogsData.length,
                )}
                {item.serviceOrdersLogsData.length > 0 && (
                  <IconButton color='primary' onClick={() => handleOpenChangesDialog(item)}>
                    <ReportGmailerrorredIcon style={{ color: 'red' }} fontSize='large' />
                  </IconButton>
                )}
              </TableCell>
              <TableCell align='center'>
                <IconButton color='primary' onClick={() => handleOpenDialog(item)}>
                  <AddCircleIcon fontSize='large' />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>

      <TablePagination
        component='div'
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <WaitingOracleBarcodeDialog
        isOpen={isOpenDialog}
        handleClose={handleCloseDialog}
        data={itemSelect}
      />

      <WaitingOracleChangesDialog
        isOpen={isOpenChangesDialog}
        handleClose={handleCloseChangesDialog}
        logs={itemSelect?.serviceOrdersLogsData}
      />
    </>
  );
}
