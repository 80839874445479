import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';

import { Text } from 'components';

import { ManualDialogContext } from '../../..';

export function ClientForm() {
  const { client, setClient, containers, setContainers, handleNext } =
    useContext(ManualDialogContext);

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Text
          label='Cliente/Ganhador'
          size='small'
          variant='outlined'
          value={client}
          onInput={toInputUppercase}
          onChange={(e) => setClient(e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Text
          label='Lotes'
          size='small'
          variant='outlined'
          type='number'
          value={containers}
          onChange={(e) => setContainers(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
