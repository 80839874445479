import React, { createContext, useState } from 'react';

import { Spacer } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { warehouseService } from 'services';

import { ExpeditionTables } from './ExpeditionTables';

export const WarehouseExpeditionContext = createContext();

export function ExpeditionGenerate() {
  const [search, setSearch] = useState('');
  const [status] = useState(2);

  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(100);

  const [requests, , loadingRequests, refetchRequests] = useQuery(
    () => warehouseService.listRequests(page, rowsPerPage, search, status, true),
    [search, page, status],
  );

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }
  return (
    <>
      <Spacer size={32} />
      <PageTitle>Expedição Warehouse</PageTitle>

      <WarehouseExpeditionContext.Provider
        value={{ requests, loadingRequests, handleChangePage, page, refetchRequests }}
      >
        {/* <Spacer size={64} /> */}
        <ExpeditionTables />
      </WarehouseExpeditionContext.Provider>
    </>
  );
}
