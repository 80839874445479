import React, { useState } from 'react';

import { Button } from 'components';

import { ManualDialog } from '../ManualDialog';

export function ManualButton() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function handleOpenDialog() {
    setIsDialogOpen(true);
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  return (
    <>
      <Button fullWidth onClick={handleOpenDialog}>
        Pedido manual
      </Button>

      <ManualDialog isOpen={isDialogOpen} handleClose={handleCloseDialog} />
    </>
  );
}
