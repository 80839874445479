import { gql } from '@apollo/client';

export const VALID_B2C_ORDER = gql`
  mutation ValidB2COrder($data: [ValidB2COrderInput!]!) {
    validB2COrder(data: $data) {
      anymarketId
      orderCode
      marketplace
      status
      deliveryMethod
      orderDate
      client
      cgc
      __typename @skip(if: true)
      items {
        sku
        totalProductValue
        __typename @skip(if: true)
      }
    }
  }
`;

export const CREATE_B2C_PRODUCTS = gql`
  mutation CreateB2COrder($data: [CreateB2COrderInput!]!) {
    createB2COrder(data: $data)
  }
`;
