import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';

import { ManualDialogContext } from '../..';

export function Navigation() {
  const { activeStep, handleNext, handleBack, client } = useContext(ManualDialogContext);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={6}>
        {activeStep !== 0 && (
          <Button fullWidth onClick={handleBack} color='secondary'>
            Voltar
          </Button>
        )}
      </Grid>
      <Grid item xs={12} xl={6}>
        <Button fullWidth onClick={handleNext} disabled={client === ''}>
          Continuar
        </Button>
      </Grid>
    </Grid>
  );
}
