import React, { useContext, useRef, useState } from 'react';

import { WaitingAllocationContext } from '..';

import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { useMutation } from '@apollo/client';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TablePagination } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Spacer, Table, Text } from 'components';
import { CREATE_ORACLE_JOURNEY_BY_FILE } from 'services/graphql/query/oracle-journey-resolver';

import { ImportPO } from './ImportPO';

const columns = [
  { label: 'Voucher', align: 'center' },
  { label: 'IMEI', align: 'center' },
  { label: 'SKU', align: 'center' },
  { label: 'Produto', align: 'center' },
  { label: 'Triagem funcional', align: 'center' },
  { label: 'Grade', align: 'center' },
  { label: 'RI', align: 'center' },
  { label: 'Nota fiscal', align: 'center' },
  { label: 'Ações', align: 'center' },
];

function getGradeColor(gradeId) {
  switch (gradeId) {
    case 1:
      return {
        label: 'LIKE NEW',
        color: '#FFFFFF', // Branco para LIKE NEW
        textColor: '#000000',
        border: '1px solid #000000',
      };
    case 2:
      return { label: 'EXCELENTE', color: '#0000FF', textColor: '#FFFFFF' }; // Azul para EXCELENTE
    case 3:
      return { label: 'MUITO BOM', color: '#008000', textColor: '#FFFFFF' }; // Verde para MUITO BOM
    case 4:
      return { label: 'BOM', color: '#FFFF00', textColor: '#000000' }; // Amarelo para BOM+
    case 5:
      return { label: 'REGULAR', color: '#FF0000', textColor: '#FFFFFF' }; // Vermelho para BOM
    case 6:
      return { label: 'QUEBRADO', color: '#000000', textColor: '#FFFFFF' }; // Preto para QUEBRADO
    default:
      return { label: 'SEM GRADE', color: '#CCCCCC', textColor: '#000000' }; // Cor padrão (cinza)
  }
}

export function WaitingAllocationTable() {
  const {
    data,
    loading,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearchChange,
    handleOpenDialog,
    page,
    rowsPerPage,
    totalCount,
    search,
  } = useContext(WaitingAllocationContext);
  const inputFileRef = useRef(null);
  const [importing, setImporting] = useState(false);

  const [submitByFile, options] = useMutation(CREATE_ORACLE_JOURNEY_BY_FILE, {
    onCompleted: () => {
      toast.success('Dados salvos com sucesso');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar os dados';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const handleFileChange = (e) => {
    setImporting(true); // Começar o loading
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (!file.name.match('.csv')) {
        toast.error('Adicione um arquivo do tipo CSV');
        setImporting(false); // Parar o loading em caso de erro
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const result = event.target.result;

          const lines = result
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line.length > 0);

          const [header, ...dataLines] = lines;

          const products = dataLines
            .map((line) => {
              console.log('line ==>', line);
              const values = line.split(';');
              console.log('values ==>', values);

              if (
                values[4] == null ||
                values[10] == null ||
                values[14] == null ||
                values[4] == '' ||
                values[10] == '' ||
                values[14] == ''
              ) {
                return null;
              }

              return {
                sinister: values[4],
                ri: values[14],
                invoice: values[10],
              };
            })
            .filter((item) => item !== null);
          console.log('products ==>', products);
          await submitByFile({
            variables: { data: products },
          });
        } catch (err) {
          toast.error('Erro ao importar, tente novamente!');
          setImporting(false); // Parar o loading em caso de erro
        }
      };
      reader.readAsText(file);
    } catch (err) {
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false); // Parar o loading em caso de erro
    } finally {
      handleReset();
      setImporting(false);
    }
  };

  const handleReset = () => {
    inputFileRef.current.value = '';
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <ImportPO handleImportClick={handleImportClick} loading={importing || options?.loading} />
        </Grid>
      </Grid>

      <Spacer size={54} />

      <Table
        headers={columns}
        striped
        emptyMessage='Nenhum produto encontrado.'
        loading={loading}
        disableNumeration
      >
        {data?.findAllServiceForStockAlocation?.serviceOrders?.map((item, index) => {
          const grade = getGradeColor(item?.grade?.id);
          return (
            <TableRow key={index}>
              <TableCell align='center'>{item?.sinister}</TableCell>
              <TableCell align='center'>{item?.imei}</TableCell>
              <TableCell align='center'>{item?.sku?.sku}</TableCell>
              <TableCell align='center'>
                {item?.sku?.brand?.description +
                  ' ' +
                  item?.sku?.model?.description +
                  ' ' +
                  item?.sku?.storage?.description +
                  ' ' +
                  item?.sku?.color?.description}
              </TableCell>
              <TableCell align='center'>{item?.functionalScreeningResult}</TableCell>
              <TableCell align='center'>
                <Chip
                  label={grade.label}
                  style={{
                    backgroundColor: grade.color,
                    color: grade.textColor,
                    border: grade.border || 'none',
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                {item.ri ? (
                  <CheckCircleIcon style={{ color: 'green' }} />
                ) : (
                  <CancelIcon style={{ color: 'red' }} />
                )}
              </TableCell>
              <TableCell align='center'>
                {item.invoice ? (
                  <CheckCircleIcon style={{ color: 'green' }} />
                ) : (
                  <CancelIcon style={{ color: 'red' }} />
                )}
              </TableCell>
              <TableCell align='center'>
                <IconButton
                  color='primary'
                  onClick={() => handleOpenDialog(item)}
                  disabled={!item.invoice || !item.ri}
                >
                  <AddCircleIcon fontSize='large' />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>

      <TablePagination
        component='div'
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
