import React from 'react';

import { Tabs } from 'components';

import { ExpeditionFinalized } from './components/ExpeditionFinalized';
import { ExpeditionGenerate } from './components/ExpeditionGenerate';

export function WarehouseExpedition() {
  return (
    <Tabs>
      <Tabs.Content label='Geração de minuta' disablePadding>
        <ExpeditionGenerate />
      </Tabs.Content>
      <Tabs.Content label='Finalizados' disablePadding>
        <ExpeditionFinalized />
      </Tabs.Content>
    </Tabs>
  );
}
