import React, { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { useMutation } from '@apollo/client';
import { Button } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';
import { VALID_B2C_ORDER } from 'services/graphql/query/b2c';

import { B2CContext } from '../..';

const examples = [
  {
    value: 1,
    label: 'Utilize o layout do ANY_MARKET',
  },
  {
    value: 2,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 3,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 4,
    label: 'Salve o arquivo como .csv',
  },
];

export function ImportButton() {
  const { setResults } = useContext(B2CContext);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [importing, setImporting] = useState(false);

  const inputFileRef = useRef(null);

  const [validProducts] = useMutation(VALID_B2C_ORDER, {
    onCompleted: (data) => {
      setResults(data.validB2COrder);
      toast.success('Produtos validados com sucesso!');
      setImporting(false);
      handleReset();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao importar, tente novamente!';

      toast.error(`Error: ${errorMessage}`);
      setImporting(false);
    },
  });

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleReset = () => {
    inputFileRef.current.value = '';
  };

  async function handleImportStore(e) {
    setImporting(true); // Começar o loading
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (!file.name.match('.csv')) {
        toast.error('Adicione um arquivo do tipo CSV');
        setImporting(false); // Parar o loading em caso de erro
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const result = event.target.result;

          const lines = result
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line.length > 0);

          const [header, ...dataLines] = lines;

          const products = dataLines
            .map((line) => {
              const values = line.split(';');
              if (
                values[0] == null || //ID ANYMARKET
                values[2] == null || //CPF/CNPJ
                values[3] == null || //CLIENTE
                values[4] == null || //TELEFONE
                values[5] == null || //EMAIL
                values[6] == null || //CÓDIGO PEDIDO
                values[7] == null || //DATA PEDIDO
                values[8] == null || //MARKETPLACE
                values[9] == null || //MUNICÍPIO
                values[10] == null || //ESTADO
                values[11] == null || //CEP
                values[12] == null || //LOGRADOURO
                values[13] == null || //NÚMERO
                values[14] == null || //COMPLEMENTO
                values[15] == null || //BAIRRO
                values[16] == null || //FORMA DE ENTREGA
                values[25] == null || //STATUS
                values[28] == null || //VALOR TOTAL DOS PRODUTOS
                values[58] == null //SKU DO PRODUTO NO MARKETPLACE
              ) {
                return null;
              }

              return {
                anymarketId: values[0],
                cgc: values[2],
                client: values[3],
                phone: values[4],
                email: values[5],
                orderCode: values[6],
                orderDate: values[7],
                marketplace: values[8],
                city: values[9],
                state: values[10],
                postalCode: values[11],
                street: values[12],
                number: values[13],
                complement: values[14],
                neighborhood: values[15],
                deliveryMethod: values[16],
                status: values[25],
                totalProductValue: values[28],
                marketplaceProductSku: values[58],
              };
            })
            .filter((item) => item !== null);
          console.log('products ==> ', products);
          await validProducts({
            variables: { data: products },
          });
        } catch (err) {
          toast.error('Erro ao importar, tente novamente!');
          setImporting(false); // Parar o loading em caso de erro
        }
      };
      reader.readAsText(file);
    } catch (err) {
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false); // Parar o loading em caso de erro
    } finally {
      handleReset();
    }
  }

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(e) => handleImportStore(e)}
        accept='text/csv'
        hidden
      />
      <Grid containet spacing={2}>
        <Grid item xs={12} xl={3}>
          <Button fullWidth onClick={handleOpenDialog} disabled={importing} loading={importing}>
            Importar pedidos B2C
          </Button>
        </Grid>
      </Grid>
      <DialogInformationForImport
        open={isOpenDialog}
        handleClose={handleCloseDialog}
        examples={examples}
        importFile={handleImportClick}
      />
    </>
  );
}
