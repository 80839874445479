import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';

const examples = [
  {
    value: 1,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 2,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 3,
    label: 'Salve o arquivo como .csv',
  },
];

export function ImportButton() {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [importing, setImporting] = useState(false);

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  async function handleImportStore(e) {
    setImporting(true); // Começar o loading
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (!file.name.match('.csv')) {
        toast.error('Adicione um arquivo do tipo CSV');
        setImporting(false); // Parar o loading em caso de erro
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const result = event.target.result;

          const lines = result
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line.length > 0);

          const [header, ...dataLines] = lines;

          const products = dataLines
            .map((line) => {
              const values = line.split(';');
              if (
                values[0] == null ||
                values[1] == null ||
                values[3] == null ||
                values[4] == null ||
                values[5] == null ||
                values[7] == null ||
                values[10] == null ||
                values[12] == null ||
                values[13] == null ||
                values[14] == null ||
                values[15] == null ||
                values[20] == null ||
                values[23] == null ||
                values[27] == null
              ) {
                return null;
              }

              return {
                sinister: values[0],
                sku: values[1],
                product: values[3],
                condition: values[4],
                imei: values[5],
                aprove_date: values[7],
                value: values[10],
                client_name: values[12],
                cgc: values[13],
                email: values[14],
                phone_number: values[15],
                zip_code: values[20],
                store: values[23],
                batch: values[27],
              };
            })
            .filter((item) => item !== null);

          // const { data } = await validProducts({
          //   variables: { data: products, provider_id: getProviderSelect },
          // });

          // setResults(data?.validProductWithServiceOrder);
        } catch (err) {
          toast.error('Erro ao importar, tente novamente!');
          setImporting(false); // Parar o loading em caso de erro
        }
      };
      reader.readAsText(file);
    } catch (err) {
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false); // Parar o loading em caso de erro
    } finally {
      handleReset();
    }
  }

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(e) => handleImportStore(e)}
        accept='text/csv'
        hidden
      />

      <Button
        fullWidth
        startIcon={<AddCircleIcon />}
        onClick={() => handleOpenDialog()}
        color='secondary'
      >
        Importar pedido
      </Button>

      <DialogInformationForImport
        open={isOpenDialog}
        handleClose={handleCloseDialog}
        examples={examples}
        importFile={handleImportClick}
      />
    </>
  );
}
