import { gql } from '@apollo/client';

export const CREATE_SERVICE_ORDER = gql`
  mutation CreateServiceOrder($data: [CreateServiceOrderInput!]!) {
    createServiceOrder(data: $data)
  }
`;

export const VALID_SERVICE_ORDER = gql`
  mutation ValidProductWithServiceOrder($data: [ValidServiceOrderInput!]!, $provider_id: Int!) {
    validProductWithServiceOrder(data: $data, provider_id: $provider_id) {
      sinister
      sku
      product
      provider_id
      condition
      imei
      aprove_date
      value
      client_name
      cgc
      email
      phone_number
      zip_code
      store
      batch
      error
      __typename @skip(if: true)
    }
  }
`;

export const RECEIVE_SERVICE_ORDER = gql`
  mutation ReceiveServiceOrder($sinister: String, $imei: String) {
    receiveServiceOrder(sinister: $sinister, imei: $imei) {
      imei
      sku {
        sku
        brand {
          id
          description
          __typename @skip(if: true)
        }
        model {
          id
          description
          __typename @skip(if: true)
        }
        storage {
          id
          description
          __typename @skip(if: true)
        }
        color {
          id
          description
          __typename @skip(if: true)
        }
      }
      sinister
      __typename @skip(if: true)
    }
  }
`;

export const FIND_SERVICE_ORDER_BY_IMEI_OR_SINISTER = gql`
  query FindServiceOrderByImeiOrSinister($sinister: String, $status_id: Int!) {
    findServiceOrderByImeiOrSinister(sinister: $sinister, status_id: $status_id) {
      imei
      sinister
      sku {
        sku
        brand {
          id
          description
          __typename @skip(if: true)
        }
        model {
          id
          description
          __typename @skip(if: true)
        }
        storage {
          id
          description
          __typename @skip(if: true)
        }
        color {
          id
          description
          __typename @skip(if: true)
        }
      }
      __typename @skip(if: true)
    }
  }
`;

export const FIND_ALL_SERVICE_FOR_STOCK_ALOCATION = gql`
  query FindAllServiceForStockAlocation($limit: Int, $offset: Int, $search: String) {
    findAllServiceForStockAlocation(limit: $limit, offset: $offset, search: $search) {
      totalCount
      serviceOrders {
        imei
        functionalScreeningResult
        ri
        invoice
        sku {
          sku
          brand {
            description
            __typename @skip(if: true)
          }
          model {
            description
            __typename @skip(if: true)
          }
          storage {
            description
            __typename @skip(if: true)
          }
          color {
            description
            __typename @skip(if: true)
          }
        }
        grade {
          id
          description
          __typename @skip(if: true)
        }
        sinister
        __typename @skip(if: true)
      }
    }
  }
`;

export const FIND_RI_INVOICE_AND_PO_PROCESS = gql`
  query FindRiInvoiceAndPoProcess($sinister: String!) {
    findRiInvoiceAndPoProcess(sinister: $sinister) {
      sinister
      ri
      invoice
    }
  }
`;

export const CREATE_RI_INVOICE_AND_PO_PROCESS = gql`
  mutation CreateRiInvoiceAndPoProcess($data: CreateInvoiceAndPoProcessInput!) {
    createRiInvoiceAndPoProcess(data: $data)
  }
`;

export const FIND_ALL_SERVICE_FOR_WAITING_ORACLE = gql`
  query FindAllServiceForWaitingOracle($limit: Int, $offset: Int, $search: String) {
    findAllServiceForWaitingOracle(limit: $limit, offset: $offset, search: $search) {
      totalCount
      serviceOrders {
        imei
        functionalScreeningResult
        ri
        invoice
        sku {
          sku
          brand {
            description
            __typename @skip(if: true)
          }
          model {
            description
            __typename @skip(if: true)
          }
          storage {
            description
            __typename @skip(if: true)
          }
          color {
            description
            __typename @skip(if: true)
          }
        }
        grade {
          id
          description
          alterDescription
          __typename @skip(if: true)
        }
        sinister
        serviceOrdersLogsData {
          id
          description
          createdAt
          __typename @skip(if: true)
        }
        __typename @skip(if: true)
      }
    }
  }
`;

export const CONFIRM_ORACLE = gql`
  mutation ConfirmOracle($data: [ConfirmOracleInput!]!) {
    confirmOracle(data: $data)
  }
`;

export const LIST_SERVICES = gql`
  query ListServices(
    $limit: Int
    $offset: Int
    $search: String
    $status: [Int!]!
    $grade: [Int!]!
    $sku: [String!]!
  ) {
    listServices(
      limit: $limit
      offset: $offset
      search: $search
      status: $status
      grade: $grade
      sku: $sku
    ) {
      totalCount
      serviceOrders {
        imei
        functionalScreeningResult
        condition
        sinister
        createdAt
        updatedAt
        __typename @skip(if: true)
        grade {
          id
          description
          __typename @skip(if: true)
        }
        sku {
          sku
          brand {
            description
            __typename @skip(if: true)
          }
          model {
            description
            __typename @skip(if: true)
          }
          storage {
            description
            __typename @skip(if: true)
          }
          color {
            description
            __typename @skip(if: true)
          }
        }
        stock {
          description
          __typename @skip(if: true)
        }
        status {
          description
          __typename @skip(if: true)
        }
        serviceOrderReport {
          id
          report
          __typename @skip(if: true)
        }
      }
    }
  }
`;

export const UPDATE_SERVICE_ORDER_PRODUCT = gql`
  mutation UpdateServiceOrderProduct($data: UpdateServiceOrderProductInput!) {
    updateServiceOrderProduct(data: $data)
  }
`;

export const UPDATE_SERVICE_ORDER_IMEI = gql`
  mutation UpdateServiceOrderImei($data: UpdateServiceOrderImeiInput!) {
    updateServiceOrderImei(data: $data)
  }
`;

export const FIND_AVAIABLE_PRODUCT_BY_SINISTER_OR_IMEI = gql`
  mutation FindAvaiableProductBySinisterOrImei($search: String) {
    findAvaiableProductBySinisterOrImei(search: $search) {
      sinister
      productSku
      __typename @skip(if: true)
    }
  }
`;

export const FIND_FIFO_PRODUCT = gql`
  mutation FindFifoProduct($search: String, $gradeId: Int) {
    findFifoProduct(search: $search, gradeId: $gradeId) {
      sinister
      productSku
      __typename @skip(if: true)
    }
  }
`;
